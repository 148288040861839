.mainContainer {
    max-height: 100vh;
    width: 100%;
    max-width: 940px;
}

.mainContainerTitle {
  div {
      border-top: 0 !important;
  }

  .containerTitleForm {
      border-left: 0 !important;
      span {
          font-weight: 600;
      }
  }
}
.formContainer {
    height: auto !important;
}

.ContainerForm {
  padding-top: 16px !important;
    & > span {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
}

.buttonRow {
    margin-top: auto;
    border-top: 1px solid #E0E0E0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    justify-content: flex-end !important;

    & > button:first-child {
        margin-right: 0 !important;
    }


    button {
        padding: 16px;
        height: 40px;
    }

    .submitButton {
        background: #5b85ef;
    }
}
@import 'react-vertical-timeline-component/style.min.css';
/* poppins-300 - latin-ext_latin_devanagari */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-300.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-300italic - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-300italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-300italic.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-regular.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-italic.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-500.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-500italic - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-500italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-500italic.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-600.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-600italic - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-600italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-600italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-600italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-600italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-600italic.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-700.svg#Poppins')
      format('svg'); /* Legacy iOS */
}
/* poppins-700italic - latin-ext_latin_devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/poppins-v19-latin-ext_latin_devanagari-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/poppins-v19-latin-ext_latin_devanagari-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-700italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/poppins-v19-latin-ext_latin_devanagari-700italic.svg#Poppins')
      format('svg'); /* Legacy iOS */
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', serif !important;
}

body {
  scroll-behavior: smooth;
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    @media (max-width: 990px) {
      width: 0 !important;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bcbcbc;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #999797;
  }

  .paddingChartCandlestick {
    padding: 8px 6px !important;
  }

  input {
    padding-right: 34px;
    text-overflow: ellipsis;
    overflow: auto;
  }
}
